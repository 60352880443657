import { OutletModel } from '@/models/outlet'

export const asyncFetchOutlet = () => {
  const $acl = useAclStore()
  const outletModel = new OutletModel()
  const outlet = ref<OutletType>()
  dataFetchList<OutletType>(outletModel, {
    filters: {
      $or: [
        // own outlet
        { managerId: $acl.currentUser?.id },
        // assigned / referred outlet
        ...($acl.currentUser.customData?.outletId
          ? [
              { _id: $acl.currentUser.customData?.outletId },
            ]
          : []),
      ],
    },
    page: 1,
    limit: 99,
    sorts: [
      {
        field: '_id',
        dir: 'asc',
      },
    ],
  }).then((resOutlet) => {
    // console.log('resOutlet', resOutlet)
    outlet.value = resOutlet.list.length > 0 ? resOutlet.list[0] : undefined
  })

  return {
    outlet
  }
}
