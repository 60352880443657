import { asyncFetchOutlet } from "./fetchOutlet";

export const useAgentBeautician = () => {
  const $acl = useAclStore();
  const user = $acl.currentUser;
  const { outlet } = asyncFetchOutlet();

  const isGuest = computed(() => {
    return !user.providerType || user.providerType === 'anon-user';
  });

  // is beautician
  const isOutletOwner = computed(() => {
    return !!user.id && outlet.value?.managerId === user.id;
  });

  // is customer of beautician
  const isOutlet = computed(() => {
    return !!user.customData?.outletId || isOutletOwner.value;
  });

  const isAgent = computed(() => {
    return !!user.customData?.teams?.includes('619f96e1d890c32696a35e64');
  });

  return {
    isOutlet,
    isAgent,
    isGuest,
    isOutletOwner,
    currentOutlet: outlet,
  };
}
